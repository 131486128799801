import { ComponentRef } from '@wix/yoshi-flow-editor';
import { getPanelUrl } from '@wix/yoshi-flow-editor/utils';

import { Experiment } from '../../constants';
import { HelpId } from '../helpId';
import { EditorScriptContext } from '../types';
import { getWidgetComponentName } from '../utils';

const panelHeightByComponent: Record<string, number> = {
  referFriendPage: 285,
  referralPageContent: 120,
  referFriendPageContent: 120,
  notificationBanner: 120,
};

export const openLayoutPanel = async (widgetRef: ComponentRef, context: EditorScriptContext) => {
  const { editorSDK, flowAPI } = context;
  const { translations, experiments, environment } = flowAPI;
  const { t } = translations;
  const componentName = await getWidgetComponentName(widgetRef, context);

  if (!componentName) {
    return;
  }

  const isWixStudio = environment.isEditorX;
  const showBreakpointsBanner = isWixStudio && experiments.enabled(Experiment.SettingsBPIndicators);
  const height = panelHeightByComponent[componentName] + (showBreakpointsBanner ? 75 : 0);

  await editorSDK.editor.openComponentPanel('', {
    title: t('layout-panel.title'),
    url: getPanelUrl(componentName, 'Layout'),
    type: editorSDK.editor.PanelType.Layout,
    helpId: HelpId.Layout,
    width: 288,
    height,
    componentRef: widgetRef,
    initialData: { widgetRef },
  });
};
